/*** Footer ***/

footer{
	background-color: $footer-bg;
	color: $footer-text-color;
	border-top: $footer-border-color solid 2px;
	border-bottom: $footer-border-color solid 2px;
	padding-top: 10px;

	.btnwidth{
		padding-left: 100px;
		padding-right: 100px;
		font-size: 18px;
	}

	.footer-info{
		background-color: $footer-info-bg;
		margin-top: -15px;
	}

	.copyright{
		margin-top: 10px;
	}

	.footer-links{
		
		margin-top: 20px;

		a{
		color: #fff;
		}

		a:hover{
			color: #fff;
		}
	}

}

@media screen and (max-width: 767px){
	footer{
		text-align: center;

		.footer-info{
			padding-bottom: 10px;
		}
	}
}