/** NAV_AREA **/
.navbar{
	border-radius: 0px;
	margin-bottom: 0;
	padding: 0 1rem;
	border: 1px solid $navbar-dark-border;
}

#navbar{
	margin-left: -12px;
	//margin-right: -15px;
}

.navbar a{
	font-size: 1.125rem;
	line-height: 25px;
}

.navbar-expand-lg .navbar-nav > li > a:hover, .navbar-expand-lg .navbar-nav > li > a:focus,
.navbar-expand-lg .navbar-nav > .active > a:focus, .navbar-expand-lg .navbar-nav > .active > a:hover,
.navbar-expand-lg .navbar-nav .dropdown-menu > li > a:focus, .navbar-expand-lg .navbar-nav .dropdown-menu > li > a:hover,
.navbar-expand-lg .navbar-nav .open .dropdown-menu > li > a:focus, .navbar-expand-lg .navbar-nav .open .dropdown-menu > li > a:hover
{
	text-decoration: underline;
}

.navbar-expand-lg .navbar-nav .nav-link{
	padding: 0.781rem 0.938rem;

}

.nav-item.active{
	background-color: rgba(255,255,255,0.1);
}

.navbar-nav > li > .dropdown-menu{
	margin-top: 0px;
	margin-left: -0.5px;
	border-radius: 0;
}

.affix{
	width: 100%;
	z-index: 999;
	top: 0;
	margin-bottom: 50px;
}

.padded{
	margin-top: 15px;
}

.navbar-dark .navbar-toggler-icon{
	background-image: none;
}
