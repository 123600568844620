/** Sidebar **/
.sidebar{
	margin-right: -30px;
	padding-bottom: 10px;

	.sideelement{
		padding-right: 30px;
		text-align: center;
		max-height: 100%;
		white-space: normal;

		h2{
			padding-top: 10px;
		}
	}

	.imgsidebar{
		display: block;
	    margin: auto;
	    margin-bottom: 5px;
	}
}

@media screen and (min-width: 768px){
	.sidebar{
		border-left: 1px solid $primary;
	}
}