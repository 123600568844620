/** LOGO_AREA **/
#logo{
	background-image: url(../img/lines.png);
	background-repeat:repeat-x;
	margin-top:10px;
	padding-bottom: 10px;
	background-color: #fff;
	box-shadow: 0 0 1px rgba(57,70,78,.15), 0 20px 55px -8px rgba(57,70,78,.25);

	img {
		margin-left: 5%;	
	}
}