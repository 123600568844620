/**
 CSS fuer Katalog
**/

.hidden{
	display: none;
}


/** Navigation **/

.catnav {

	margin-left: -15px;
	margin-right: -15px;


	.hidden{
		display: none;
	}

	li{
		background-color: $white;
		//border-left: 1px solid $primary;
		//border-right: 1px solid $primary;
		border-bottom: 1px solid $primary;

		a{
			color: $dark-gray;
			font-weight: bold;
		}

	}
	
	li.active{
    background-color: $primary;
    
    a{
			color: $white;
		}
	} 

	li:first-child{
		border-top: 1px solid $primary;		
	}

	li.first a:before,
	li.second a:before{
		/*content: '\f105';
		font-family: FontAwesome;*/
		@include fa-icon;
		@extend .fas;
		content: fa-content($fa-var-angle-right);
		color: $primary;
		margin-right: 5px;
	}

	li.first.active a:before,
	li.second.active a:before{
		/*content: '\f105';
		font-family: FontAwesome;*/
		@include fa-icon;
		@extend .fas;
		content: fa-content($fa-var-angle-right);
		color: $white;
		margin-right: 5px;
	}

	li.first.multi.active a:before,{
		/*content: '\f105';
		font-family: FontAwesome;*/
		@include fa-icon;
		@extend .fas;
		content: fa-content($fa-var-angle-down);
		color: $white;
		margin-right: 5px;
	}

	li.second a{
		margin-left: 15px;
	}

	h3{
		margin-left: 15px;
	}
	

	.active + ul{
		display: block;
	}

	.subcat .active{
		display: block
	}

}


.subhead{
	color: $gray-700;
	text-align: center;
	font-size: 87%;
	
}

.subhead.description{
	color: $dark-gray
}


/** Kategorie übersucht **/
.categories, .featuredArticles, .newestArticles{
	.row{
		margin-bottom: 25px;
		padding-bottom: 25px;
	}

	.row.second{
		margin-top: -25px;
	}

	.card{
		height: 100%;

		a:hover{
			text-decoration: none;
		}

		.card-header{
			/*min-height: 100px;
			white-space: initial;
			overflow: hidden;
			text-overflow: ellipsis;*/
			height: 90px;
			font-weight: bold;
			overflow-y: hidden;
		}


		.card-footer a{
			width: 100%;
		}

		img{
			display: block;
		    margin-left: auto;
		    margin-right: auto;
		    margin-top: 5px;
		    margin-bottom: 5px;
		}

		.img-featured{
	    	height: 150px;
	    }

		.price{
			font-weight: bold;
			text-align: right;
		}
	}
}

.articles{
	.row{
		/*margin-bottom: 25px;*/
		padding-bottom: 25px;
	}

	.row.second{
		margin-top: -25px;
	}
}


/** Article **/
.article{
	border-bottom: 1px solid $gray-300;

	.picture{
		height: 100%;
		display: table;
		a{
			display: table-cell;
			vertical-align: middle;
			img{
				vertical-align: middle;
			}	
		}
	}

	.title{
		min-height: 50px;
		a{
			color: $text-color;
			font-weight: bold;

			
		}
	}
	.shortdesc{
		min-height: 50px;
	}
	.price{
		font-weight: 400;
		font-size: 24px;
	}
	.action{
		a{
			width: 100%;
		}
	}

	.row{
		padding-top: 25px;
	}
}

.article.odd{
	border-right: 1px solid $gray-300;
	/*margin-top: -25px;*/
	/*padding-top: 25px;*/
}


.pagination {
   justify-content: center;
}

#productHeader, #paramter, #productDescription{
	padding-bottom: 15px;
	border-bottom: 1px solid $gray-300;
}

#paramter{
	margin-top: 10px;
	padding-bottom: 10px;
}

#productHeader{
	#articleTitle{
		font-size: 1.5rem;
	}

	small, .stockbox{
		font-size: 87%;
	}

	.shortDescription{
		margin-bottom: 22px;
	}

	.pricebox{
		font-size: 1.35rem;
	}

	.picture{
		height: 100%;
		display: table;
		a{
			display: table-cell;
			vertical-align: middle;
			img{
				vertical-align: middle;
			}	
		}
	}
}

#productDescription{
	
	margin-top: 15px;

	#tabContent{
		border-left: 1px solid $gray-300;
		border-right: 1px solid $gray-300;
		border-bottom: 1px solid $gray-300;
		padding: 10px 15px;	
	}
}

#productExtras{
	.accessoryRow{
		margin: 0;
	}

	.accessory{
		border-left: 1px solid $gray-300;
		border-bottom: 1px solid $gray-300;
		min-width: 25%;
	}

	.fill{
		width: 300px;
	}

	.picture{
		height: 200px;
		max-height: 200px;
		max-width: 100%;
		display: table;
		a{
			display: table-cell;
			vertical-align: middle;
			img{
				vertical-align: middle;
			}	
		}
	}
	.img-fluid{
		max-height: 200px;
	}

	.accessoryName{
		font-weight: bold;
		font-size: 0.9rem;
		height: 48px;
	}
	
	.accessoryAction{
		padding-bottom: 5px;
	}

	.accessoryAction a{
		width: 100%;
	}
}
