/**
 Eigene Styles
**/

/*** Allgemeines ***/


h1, h2, h3{
	margin-top: .625em;
	margin-bottom: .3125em;
}

#content .container h1, #content .container h2,
.sidebar .sideelement h1, .sidebar .sideelement h2{
	margin-left: -15px;
	text-align: center;
	padding: 5px 0 5px 15px;
}

#content .container h1:after, #content .container h2:after,
.sidebar .sideelement h1:after, .sidebar .sideelement h2:after{
	content:' ';
    display:block;
    border:1px solid $primary;
    border-radius:2px;
    -webkit-border-radius:2px;
    -moz-border-radius:2px;
    box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
    -webkit-box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
    -moz-box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
    width: 60%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3px;
}

/** Layout **/
#content {
	
	text-align: justify;
	margin-right: 15px;
	margin-left: 15px;

	.container{
		background-color: $white;
		box-shadow: 0 0 1px rgba(57,70,78,.15), 0 20px 55px -8px rgba(57,70,78,.25);

	}

	.textCenter{
		text-align: center;
		font-weight: bold;
	}
}

.maincontent{
	min-height: 700px;
	margin-bottom: 15px;
	//max-width: 1170px; // Alter Break Point
}

@media only screen and (min-width : 481px) {
   .flex-row.row {
	 display: flex;
	 flex-wrap: wrap;
   }
   .flex-row.row > [class*='col-'] {
	 display: flex;
	 flex-direction: column;
   }
  .flex-row.row:after, 
  .flex-row.row:before {
	 display: flex;
  }
}

/* Thumbnails */
.thumb{
	text-align: center;
	
}

.thumb-werkzeuge{
	min-height: 100%;
	display: flex;
}

/** Javascript Error **/
.jserror{
	text-align:center;
	position: absolute;
	z-index: 999;
}

.flag-icon{
	opacity: 0.4;
    filter: alpha(opacity=40); /* msie */
    background-color: #000;
}

.flag-active{
	opacity: 1;
    filter: alpha(opacity=100); /* msie */
}

.flag-icon:hover{
	opacity: 1;
    filter: alpha(opacity=100); /* msie */
}