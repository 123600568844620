// Body
$body-bg: #f5f8fa;

// Colors // Variablen teilweise aus der Bootstrap Variablen Datein übernommen
$blue:    									#004180 !default; 	// Standard Blau für Layout // Angepasst
$white:    									#fff !default;		// Weis für Effekte, Buttons, etc.
$dark-gray:									#222222 !default;	// Dunkles Grau für Text-Darben

$white:    									#fff !default;
$gray-100:									#f8f9fa !default;
$gray-200: 									#e9ecef !default;
$gray-300: 									#dee2e6 !default;
$gray-400: 									#ced4da !default;
$gray-500: 									#adb5bd !default;
$gray-600: 									#6c757d !default;
$gray-700: 									#495057 !default;
$gray-800: 									#343a40 !default;
$gray-900: 									#212529 !default;
$black:    									#000 !default;

$blue:    									#004180 !default; //#007bff !default;
$indigo:  									#6610f2 !default;
$purple:  									#6f42c1 !default;
$pink:    									#e83e8c !default;
$red:     									#dc3545 !default;
$orange:  									#fd7e14 !default;
$yellow:  									#ffc107 !default;
$green:   									#28a745 !default;
$teal:    									#20c997 !default;
$cyan:    									#17a2b8 !default;

$primary:       							$blue !default;
$secondary:     							$gray-600 !default;
$success:       							$green !default;
$info:          							$cyan !default;
$warning:       							$yellow !default;
$danger:        							$red !default;
$light:         							$gray-100 !default;
$dark:         								$gray-800 !default;

//Helper
//$btn-primary-color: 						$primary;

// Typography
$font-family-sans-serif: 					"Roboto", "Raleway", sans-serif;
$font-size-base: 							1rem;
$text-color: 								$gray-900;

// Nav Bar
$navbar-dark-color:                 		$white;
$navbar-dark-hover-color:           		$white;
$navbar-dark-active-color:          		$white;

$navbar-dark-border: 						darken($blue, .5);

$dropdown-bg:                       		$primary;
$dropdown-link-color:               		$white;
$dropdown-link-hover-color:         		$white;
$dropdown-link-hover-bg:            		$primary;

$dropdown-link-active-color:        		$white;
$dropdown-link-active-bg:           		rgba(255,255,255,0.1);

//Footer
$footer-text-color:							$white;
$footer-bg:									$primary;
$footer-border-color:						$navbar-dark-border;
$footer-info-bg:							rgba(255,255,255,0.1);

// Buttons
$btn-default-color: 						$text-color;
$btn-primary-color:							$white;