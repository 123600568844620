
// Fonts
//@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");
//@import url("https://fonts.googleapis.com/css?family=Roboto&text=0123456789");
//@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,600");
@import "fonts";

// Variables
@import "variables";

// Eigene Variablen
@import "custom_variables.scss";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome
//@import '~font-awesome/scss/font-awesome';

// Font Awesome 5
//$fa-font-path:   "../webfonts";
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';

// Flag Icons
//@import "~flag-icon-css/sass/flag-icon";

//Eigene SASS Pages
@import "styles.scss";
@import "logo.scss";
@import "navigation.scss";
@import "sidebar.scss";
@import "footer.scss";
@import "catalog.scss";

// Cookie
@import "cookieconsent.min.css";

//.navbar-laravel {
//  background-color: #fff;
//  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
//}

